import type { AccommodationDto, SingleTicketDto } from '@hcr/api/consumer'
import type { DestinationDto } from '@hcr/api/optimizely'
import { isUndefined } from '@hcr/utils'

export const isBookingIn =
  <T extends AccommodationDto | SingleTicketDto>(destination: DestinationDto | undefined) =>
  (booking: T): boolean => {
    if (isUndefined(destination)) {
      return false
    }

    const hasSameHotelId = destination.hotelId === String(booking.resort_id)
    const hasSameMokkiId = destination.mokkiId === String(booking.resort_id)
    return hasSameHotelId || hasSameMokkiId
  }
