import { Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { unit } from '@hcr/ui'

import { isActionRequestedCss } from './styles'

interface ContractPreviewLoadingProps {
  isActionRequested: boolean
}

export const ContractPreviewLoading: FC<ContractPreviewLoadingProps> = ({ isActionRequested }) => {
  return (
    <Stack padding={unit(4)} css={isActionRequestedCss[String(isActionRequested)]}>
      <Skeleton width='95%' height={unit(8)} variant='rectangular' />
      <Skeleton width='85%' height={unit(7)} />
      <Skeleton width='90%' height={unit(7)} />
      <Skeleton width='85%' height={unit(7)} />
    </Stack>
  )
}
