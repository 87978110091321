import { z } from 'zod'

import { BooleanDtoSchema, Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

export const HoldingWeekDtoSchema = z.strictObject({
  is_locked: BooleanDtoSchema,
  is_reservation_or_rental_request_received: BooleanDtoSchema,
  week_number: Int32DtoSchema,
  week_start: StringDtoSchema.datetime(),
  week_end: StringDtoSchema.datetime(),
})

export type HoldingWeekDto = z.infer<typeof HoldingWeekDtoSchema>
