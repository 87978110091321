import type { SerializedStyles } from '@emotion/react'
import { css } from '@mui/material'

import { COLORS } from '@hcr/ui'

export const isActionRequestedCss: Record<string, SerializedStyles> = {
  true: css`
    border: none;
    background-color: ${COLORS.vaalea['60%']};
  `,
  false: css`
    border: thin solid ${COLORS.black};
    background-color: ${COLORS.white};
  `,
}
