import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { HoldingWeekDto } from '@hcr/api/consumer'
import { COLORS, ICONS, LinkUnstyled } from '@hcr/ui'

import { Path } from '../../../../../models'
import { dateFormatter, to } from '../../../../../utils'

interface WeekPreviewProps {
  holdingId: string
  isVilla: boolean
  week: HoldingWeekDto
}

export const WeekPreview: FC<WeekPreviewProps> = ({ holdingId, isVilla, week }) => {
  const { t } = useTranslation()

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' flexWrap='nowrap'>
      <Stack justifyContent='center'>
        <Typography variant='labelM'>{t('owner-holding.week', { weekNumber: week.week_number })}</Typography>
        <Typography variant='bodyS' color={COLORS.grey[700]}>
          {dateFormatter.formatDateRange({
            from: week.week_start,
            to: week.week_end,
          })}
        </Typography>
      </Stack>
      <Stack justifySelf='end'>
        <Button
          component={LinkUnstyled}
          to={to([
            isVilla ? Path.OwnerHoldingBooking : Path.OwnerHoldingRental,
            { holdingId, weekStart: week.week_start },
          ])}
          endIcon={week.is_locked && <FontAwesomeIcon icon={ICONS.farHouseLock} />}
          disabled={week.is_locked || week.is_reservation_or_rental_request_received}
          variant='text'
          color='primary'
          style={{ textAlign: 'right' }}
        >
          {isVilla
            ? week.is_reservation_or_rental_request_received
              ? t('owner-holding.booked')
              : t('owner-holding.book')
            : week.is_reservation_or_rental_request_received
              ? t('owner-holding.rented-out')
              : t('owner-holding.rent-out')}
        </Button>
      </Stack>
    </Stack>
  )
}
