import { Box, Button, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { MocksSettings } from '@hcr/api/shared'
import { loadMocksSettings, Mocks, saveMocksSettings } from '@hcr/api/shared'
import { Checkbox, COLORS, unit } from '@hcr/ui'

import { Path } from '../../../models'
import { to } from '../../../utils'
import { LayoutNavigationBack } from '../../common'

const BACK_NAVIGATION_PATH = to(Path.About)

export const AboutMocks: FC = () => {
  const { t } = useTranslation()

  const form = useForm<MocksSettings>({ values: loadMocksSettings() })

  const onSubmit: SubmitHandler<MocksSettings> = async (settings): Promise<void> => {
    saveMocksSettings(settings)
    window.location.reload()
  }

  return (
    <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='text'>
      <Box bgcolor={COLORS.white} minHeight='100vh'>
        <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
          <Typography variant='headlineL'>{t('about-mocks.mocked-data-settings')}</Typography>
          <Typography variant='bodyM'>
            {t('about-mocks.the-settings-below-are-retained-until-you-close-the-browser-tab')}
          </Typography>
        </Stack>
        <Stack gap={unit(3)} paddingY={unit(3)} paddingX={unit(7)}>
          <form>
            <FormGroup>
              <Controller
                control={form.control}
                name={Mocks.HasAccommodations}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-accommodations')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasSingleTickets}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-single-tickets')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasActivities}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-activities')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasActiveContracts}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-active-contracts')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.HasBenefits}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.has-benefits')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.IsGoldOwner}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.is-gold-owner')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
              <Controller
                control={form.control}
                name={Mocks.IsVillasOwner}
                render={({ field }) => (
                  <FormControlLabel
                    label={t('about-mocks.is-villas-owner')}
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
            </FormGroup>
          </form>
        </Stack>
        <Stack gap={unit(3)} paddingTop={unit(4.5)} paddingX={unit(7)} paddingBottom={unit(7.5)}>
          <Button onClick={form.handleSubmit(onSubmit)} variant='contained' color='primary'>
            {t('about-mocks.reload-mocked-data')}
          </Button>
        </Stack>
      </Box>
    </LayoutNavigationBack>
  )
}
