import type { ReactNode } from 'react'

import type { OwnersDto } from '@hcr/api/optimizely'
import { isCardPrimaryDto, isCardPromotionDto } from '@hcr/api/optimizely'
import type { CreateCardPrimaryOptions, CreateCarouselOptions } from '@hcr/ui'
import { CardEmbedment, createCardPrimary, createCardPromotion, createCarousel } from '@hcr/ui'

import { createLink } from '../common'

export type CreateOwnersContentOptions = Pick<CreateCardPrimaryOptions, 'variant'> &
  Pick<CreateCarouselOptions, 'slidesMargin'>

export const createOwnersContent = ({ slidesMargin, variant }: CreateOwnersContentOptions) => {
  const cardPrimary = createCardPrimary({ createLink, embedment: CardEmbedment.Standalone, variant })
  const cardPromotion = createCardPromotion({ createLink, embedment: CardEmbedment.Standalone })
  const carousel = createCarousel({ createLink, slidesMargin })

  return (dto: OwnersDto['content'][number]): ReactNode => {
    if (isCardPrimaryDto(dto)) {
      return cardPrimary(dto)
    }

    if (isCardPromotionDto(dto)) {
      return cardPromotion(dto)
    }

    return carousel(dto)
  }
}
