import hash from 'object-hash'
import type { ReactNode } from 'react'

import type { CarouselDto } from '@hcr/api/optimizely'

import { CardEmbedment, CardVariant } from '../../surfaces'

import type { CarouselProps } from './Carousel'
import { Carousel } from './Carousel'
import type { CreateCarouselCardOptions } from './createCarouselCard'
import { createCarouselCard } from './createCarouselCard'

export type CreateCarouselOptions = Omit<CreateCarouselCardOptions, 'embedment' | 'variant'> &
  Pick<CarouselProps, 'slidesMargin'>

export const createCarousel = ({ createLink, slidesMargin }: CreateCarouselOptions) => {
  const carouselCard = createCarouselCard({
    createLink,
    embedment: CardEmbedment.Carousel,
    variant: CardVariant.Regular,
  })

  return (dto: CarouselDto): ReactNode => (
    <Carousel key={hash([dto.title])} slidesMargin={slidesMargin} title={dto.title}>
      {dto.content.map(carouselCard)}
    </Carousel>
  )
}
