import hash from 'object-hash'
import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { CarouselProps } from '@hcr/ui'
import { CardEmbedment, CardPromotion, CardVariant, Carousel, unit } from '@hcr/ui'
import { isNotNull } from '@hcr/utils'

import { LINK_CONFIG } from '../../../../../../configs'
import { useHoldingsQuery, useIdToken } from '../../../../../../hooks'
import { createHoldingPreview } from '../holding-preview'

import { HoldingsLoading } from './HoldingsLoading'

const holdingPreview = createHoldingPreview()

export const Holdings: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const holdings = useHoldingsQuery({ idToken: String(idToken) }, { enabled: isNotNull(idToken), retryOnMount: false })

  // Note: The same API error is already handled in <NextWeek />, so leaving null here to avoid the same warning being shown twice
  if (holdings.isError) {
    return null
  }

  if (holdings.isSuccess) {
    const cardPromotion: CarouselProps['children'][number] = [
      hash([t('owner.cant-see-your-holding?')]),
      <CardPromotion
        description={t('owner.registering-your-ownership-may-take-a-while')}
        embedment={CardEmbedment.Carousel}
        link={{
          isExternal: true,
          label: t('owner.more-information'),
          to: LINK_CONFIG.termsOfOwnership,
        }}
        title={t('owner.cant-see-your-holding?')}
        variant={CardVariant.Owner}
      />,
    ]

    return (
      <Carousel slidesMargin={unit(3)} title={t('owner.your-holdings')}>
        {holdings.data.map(holdingPreview).concat([cardPromotion])}
      </Carousel>
    )
  }

  return <HoldingsLoading />
}
