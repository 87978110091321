import { z } from 'zod'

import { Mocks } from './Mocks'

export const MocksSettingsSchema = z.strictObject({
  [Mocks.HasAccommodations]: z.boolean(),
  [Mocks.HasActiveContracts]: z.boolean(),
  [Mocks.HasActivities]: z.boolean(),
  [Mocks.HasBenefits]: z.boolean(),
  [Mocks.HasSingleTickets]: z.boolean(),
  [Mocks.IsGoldOwner]: z.boolean(),
  [Mocks.IsVillasOwner]: z.boolean(),
})

export type MocksSettings = z.infer<typeof MocksSettingsSchema>

export const isMocksSettings = (x: unknown): x is MocksSettings => MocksSettingsSchema.safeParse(x).success
